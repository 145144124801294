<template>
  <message-incoming class="messageIn-thread" v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <component v-for="item in items" :is="componentFn(item.event)" :originalEvent="item.event" :key="item.event.getId()"
      v-bind="{ ...$props }" v-on="$listeners" ref="exportedEvent" />
  </message-incoming>
</template>

<script>
import MessageIncoming from "../MessageIncoming.vue";
import messageMixin from "./../messageMixin";
import util from "../../../plugins/utils";

export default {
  extends: MessageIncoming,
  components: { MessageIncoming },
  mixins: [messageMixin],
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.thread = this.timelineSet.relations.getChildEventsForEvent(this.event.getId(), util.threadMessageType(), "m.room.message");
    if (!this.thread) {
      this.event.on("Event.relationsCreated", this.onRelationsCreated);
    }
  },
  beforeDestroy() {
    this.event.off("Event.relationsCreated", this.onRelationsCreated);
  },
  methods: {
    onRelationsCreated() {
      this.thread = this.timelineSet.relations.getChildEventsForEvent(this.event.getId(), util.threadMessageType(), "m.room.message");
      this.event.off("Event.relationsCreated", this.onRelationsCreated);
    },
    processThread() {
      this.items = this.timelineSet.relations.getAllChildEventsForEvent(this.event.getId())
        .filter(e => util.downloadableTypes().includes(e.getContent().msgtype))
        .map(e => {
          let ret = {
            event: e,
            src: null,
          };
          return ret;
        });
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>

<style lang="scss" scoped>
.bubble {
  width: 100%;
}
</style>