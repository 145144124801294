<template>
  <div v-if="$config.auto_join_rooms && !roomCreatedByYou() && event.getSender() == $matrix.currentUserId" class="created-room-welcome-header" style="white-space: pre-line;">
    {{ $t('room_welcome.info_auto_join',{room: room ? room.name : "",you: $matrix.currentUserDisplayName}) }}
    <div class="ma-0">
      <a href="#" text @click.prevent="viewProfile">
        {{ $t("room_welcome.change") }}
      </a>
    </div>
  </div>
  <!-- Contact joined the chat -->
  <div v-else class="messageJoin">
    {{ $t('message.user_joined',{user: eventSenderDisplayName(event)}) }}
  </div>
</template>

<script>
import messageMixin from "./messageMixin";

export default {
  mixins: [messageMixin],
  methods: {
    viewProfile() {
      this.$navigation.push({ name: "Profile" }, 1);
    },
    roomCreatedByYou() {
      const createEvent = this.room && this.room.currentState.getStateEvents("m.room.create", "");
      if (createEvent) {
        const creatorId = createEvent.getContent().creator;
        return (creatorId == this.$matrix.currentUserId);
      }
      return false;
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>